@import 'styles/mixins';

.changeAvatar {
  &_title {
    margin-bottom: 40px;
  }

  &_subtitle {
    color: #121212;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &_desc {
    text-align: center;
    color: #515151;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
  }

  &_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  &_upload_btn {
    display: flex;
    width: 200px;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    border: 1px solid #2378e5;
    background: transparent;
    outline: none;
  }

  &_upload_main {
    display: flex;
    align-items: center;
    gap: 8px;

    color: #2378e5;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  &_upload_tips {
    color: #b8b8b8;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
  }

  &_avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 52px;
  }

  &_or {
    color: #b8b8b8;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-shrink: 0;
  }

  &_avatar_list {
    display: flex;
    align-items: center;
    gap: 24px;

    @include sm {
      gap: 15px;
      width: 100%;
      overflow: auto;
      height: 60px;
    }
  }

  &_avatar_item {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
  }

  &_avatar_img {
    width: 100%;
    height: 100%;
    border: 0;

    .ant-avatar {
      border: 0;
    }
  }

  &_avatar_active {
    border: 2px solid #2378e5;
  }

  &_avatar_checked {
    position: absolute;
    bottom: -2px;
    right: 2px;
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_confirm {
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #2378e5;
    outline: 0;
    border: 0;

    color: #ffffff;
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include sm {
      width: 100%;
      border-radius: 30px;
      font-size: 16px;
    }
  }

  &_confirm_disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}